import $ from "jquery";

const escapeListener = () => {
  const iH = document.getElementById("inputHamburger");

  $(document).on("keyup", function (e) {
    if (e.key === "Escape") {
      if (iH) {
        iH.checked = false;
      }
    }
  });
};

export { escapeListener };
